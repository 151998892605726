import {computed, Injectable, signal} from '@angular/core';
import {PlatformsApiDTO, PlatformsApiInterface} from "../../../features/palataforms/palataforms.service";
import {BehaviorSubject, map, Observable} from "rxjs";
import {AuthStoreService} from "../../../features/auth/infrastructure/services/auth-store/auth-store.service";
import {HttpResponse} from "../http/HttpResponse";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {parseJson} from "@angular/cli/src/utilities/json-file";

@Injectable({
  providedIn: 'root'
})
export class PlatformStoreService {
  public readonly platform:  BehaviorSubject<PlatformsApiDTO | undefined> = new BehaviorSubject<PlatformsApiDTO | undefined>(undefined);
  public readonly availablePlatforms:  BehaviorSubject<PlatformsApiDTO[] | undefined> = new BehaviorSubject<PlatformsApiDTO[] | undefined>(undefined);
  public readonly userPlatforms = signal<PlatformsApiDTO[] | undefined>(undefined);

  currentPlatform?: PlatformsApiDTO
  constructor(
    private authStore: AuthStoreService,
    private httpClient: HttpClient
  ) {
    /*this.platform.subscribe((platform) => {
      if (platform) this.setPlatform(platform)
      else this.clearPlatform()
    })*/

    this.init()
  }

  init(){
    this.refreshPlatforms()
    const userId = computed(() => this.authStore.user()?.id);
    if (userId()) {
      this.getPlatforms(userId()!).subscribe({
        next: platforms => {
          this.userPlatforms.set(platforms)
          this.availablePlatforms.next(platforms)
          if (!this.currentPlatform && this.authStore.user()?.role != 'ADMIN_GLOBAL')
            this.setPlatform(platforms[0])
          if(!this.currentPlatform && this.authStore.user()?.role == 'ADMIN_GLOBAL')
            this.setPlatform(parseJson((this.getPlatformFromStorage()!)))
          // localStorage.setItem('platform', JSON.stringify(platforms[0]))
        },
        error: error => {
          console.log(error)
          this.platform.next(undefined)
        }
      })
    }
  }

  getPlatforms(userId: string){
    return this.httpClient.get<HttpResponse<PlatformsApiDTO[]>>(`${environment.api_url}/plataforms/user/${userId}`)
      .pipe(map(response => response.data));
  }

  setPlatform(platform: PlatformsApiDTO){
    localStorage.setItem("platform", JSON.stringify(platform));
    this.platform.next(platform)
    this.currentPlatform = platform
  }

  refreshPlatforms(){
    const userId = computed(() => this.authStore.user()?.id);
    if (userId()) {
      this.getPlatforms(userId()!).subscribe({
        next: platforms => {
          this.userPlatforms.set(platforms)
          this.availablePlatforms.next(platforms)
          if (!this.currentPlatform && this.authStore.user()?.role != 'ADMIN_GLOBAL')
            this.setPlatform(platforms[0])
          // localStorage.setItem('platform', JSON.stringify(platforms[0]))
        },
        error: error => {
          console.log(error)
          this.platform.next(undefined)
        }
      })
    }
  }
  getPlatformFromStorage(){
    return localStorage.getItem('platform')
  }

  clearPlatform(){
    localStorage.removeItem("platform");
    this.userPlatforms.set(undefined)
    this.platform.next(undefined)
    this.currentPlatform = undefined
    this.availablePlatforms.next(undefined)
  }
}
