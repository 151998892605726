// Output Feedback
var __classPrivateFieldSet = this && this.__classPrivateFieldSet || function (receiver, state, value, kind, f) {
  if (kind === "m") throw new TypeError("Private method is not writable");
  if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
  if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
  return kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value), value;
};
var __classPrivateFieldGet = this && this.__classPrivateFieldGet || function (receiver, state, kind, f) {
  if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
  if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
  return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _OFB_iv, _OFB_lastPrecipher, _OFB_lastPrecipherIndex;
import { ModeOfOperation } from "./mode.js";
export class OFB extends ModeOfOperation {
  constructor(key, iv) {
    super("OFB", key, OFB);
    _OFB_iv.set(this, void 0);
    _OFB_lastPrecipher.set(this, void 0);
    _OFB_lastPrecipherIndex.set(this, void 0);
    if (iv) {
      if (iv.length % 16) {
        throw new TypeError("invalid iv size (must be 16 bytes)");
      }
      __classPrivateFieldSet(this, _OFB_iv, new Uint8Array(iv), "f");
    } else {
      __classPrivateFieldSet(this, _OFB_iv, new Uint8Array(16), "f");
    }
    __classPrivateFieldSet(this, _OFB_lastPrecipher, this.iv, "f");
    __classPrivateFieldSet(this, _OFB_lastPrecipherIndex, 16, "f");
  }
  get iv() {
    return new Uint8Array(__classPrivateFieldGet(this, _OFB_iv, "f"));
  }
  encrypt(plaintext) {
    var _a, _b;
    if (plaintext.length % 16) {
      throw new TypeError("invalid plaintext size (must be multiple of 16 bytes)");
    }
    const ciphertext = new Uint8Array(plaintext);
    for (let i = 0; i < ciphertext.length; i++) {
      if (__classPrivateFieldGet(this, _OFB_lastPrecipherIndex, "f") === 16) {
        __classPrivateFieldSet(this, _OFB_lastPrecipher, this.aes.encrypt(__classPrivateFieldGet(this, _OFB_lastPrecipher, "f")), "f");
        __classPrivateFieldSet(this, _OFB_lastPrecipherIndex, 0, "f");
      }
      ciphertext[i] ^= __classPrivateFieldGet(this, _OFB_lastPrecipher, "f")[(__classPrivateFieldSet(this, _OFB_lastPrecipherIndex, (_b = __classPrivateFieldGet(this, _OFB_lastPrecipherIndex, "f"), _a = _b++, _b), "f"), _a)];
    }
    return ciphertext;
  }
  decrypt(ciphertext) {
    if (ciphertext.length % 16) {
      throw new TypeError("invalid ciphertext size (must be multiple of 16 bytes)");
    }
    return this.encrypt(ciphertext);
  }
}
_OFB_iv = new WeakMap(), _OFB_lastPrecipher = new WeakMap(), _OFB_lastPrecipherIndex = new WeakMap();
