export const cachedSpaces = new Array(20).fill(0).map((_, index) => {
  return ' '.repeat(index);
});
const maxCachedValues = 200;
export const cachedBreakLinesWithSpaces = {
  ' ': {
    '\n': new Array(maxCachedValues).fill(0).map((_, index) => {
      return '\n' + ' '.repeat(index);
    }),
    '\r': new Array(maxCachedValues).fill(0).map((_, index) => {
      return '\r' + ' '.repeat(index);
    }),
    '\r\n': new Array(maxCachedValues).fill(0).map((_, index) => {
      return '\r\n' + ' '.repeat(index);
    })
  },
  '\t': {
    '\n': new Array(maxCachedValues).fill(0).map((_, index) => {
      return '\n' + '\t'.repeat(index);
    }),
    '\r': new Array(maxCachedValues).fill(0).map((_, index) => {
      return '\r' + '\t'.repeat(index);
    }),
    '\r\n': new Array(maxCachedValues).fill(0).map((_, index) => {
      return '\r\n' + '\t'.repeat(index);
    })
  }
};
export const supportedEols = ['\n', '\r', '\r\n'];