import {inject} from "@angular/core";
import {
  HttpErrorResponse,
  HttpInterceptorFn,
} from "@angular/common/http";
import { catchError, EMPTY, switchMap, throwError } from "rxjs";
import {AuthApiService} from '../../../features/auth/infrastructure/services/auth-api/auth-api.service';
import {AuthStoreService} from '../../../features/auth/infrastructure/services/auth-store/auth-store.service';
import {ErrorCode} from '../../../shared/domain/ErrorCode';


export const InterceptorSkipHeader = "X-Skip-Interceptor";

export const accessTokenInterceptor: HttpInterceptorFn = (request, next) => {
  const authApi = inject(AuthApiService);
  const authStore = inject(AuthStoreService);

  if (request.headers.has(InterceptorSkipHeader)) {
    const headers = request.headers.delete(InterceptorSkipHeader);
    return next(request.clone({ headers }));
  }

  const accessToken = authStore.accessToken();
  const headers = request.headers.append("Authorization", `Bearer ${accessToken}`);
  return next(request.clone({ headers })).pipe(
    catchError((err) => {
      if (err instanceof HttpErrorResponse) {
        if (err.error.error_code !== ErrorCode.UNAUTHORIZED) {
          return throwError(() => err);
        }
      }

      const refreshToken = authStore.refreshToken()!;
      return authApi.refreshToken(refreshToken).pipe(
        switchMap((response) => {
          const headers = request.headers.append(
            "Authorization",
            `Bearer ${response.access_token}`,
          );
          return next(request.clone({ headers }));
        }),
        catchError(() => {
          authApi.logout(refreshToken).subscribe(authStore.logout);
          return EMPTY;
        }),
      );
    }),
  );
}
